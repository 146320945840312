.navbar {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    box-shadow: 0px 13px 25px -12px rgba(0, 0, 0, 0.25);
    z-index: 100;
}

.navbar.un_scrolled {
    box-shadow: none;
    opacity: 0.8;
}

.toggler_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--dark);
    z-index: 2;
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
}

.navbar_toggler {
    width: 95%;
    padding-top: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

@media only screen and (max-width: 480px) {
    .navbar_wrapper {
        width: 90% !important;
    }

    .top_navbar {
        width: 90vw !important;
    }

    .nav_btn {
        display: none !important;
    }

    .top_right {
        flex: 0;
    }
}

@media only screen and (max-width: 768px) {
    .navigation .nav_item a {
        font-size: var(--text-sm) !important;
    }

    .bottom_navbar {
        display: none !important;
    }
}

@media only screen and (max-width: 992px) {
    .navbar_toggler {
        flex-direction: column;
    }

    .navigation {
        width: 100% !important;
        padding-bottom: 30px;
    }

    .news {
        width: 100% !important;
    }

    .offering {
        width: 100% !important;
    }
}

.no_scroll {
    overflow-y: hidden;
}

.navigation {
    width: 30%;
    border-top: solid 2px white;
    text-align: left;
}

.nav_heading {
    font-size: var(--nav-text);
    color: #b9b9b9;
    padding-top: 20px;
    margin-bottom: 30px;
}

.navigation .nav_item {
    list-style: none;
    padding: 10px 0px;
}

.navigation .nav_item a {
    text-decoration: none;
    color: white;
    font-size: 2.5rem;
    padding: 20px 0px;
    transition: all 0.3s ease-in-out;
}

.navigation .nav_item a:hover {
    color: var(--gray);
}

.news {
    width: 30%;
    height: 300px;
    text-align: left;
    border-top: solid 2px white;
}

.news_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.news_wrapper .news_1,
.news_wrapper .news_2 {
    height: 100px;
}

.offering {
    width: 30%;
    height: 300px;
    text-align: left;
    border-top: solid 2px white;
}

.navbar_wrapper {
    width: 95%;
}

.top_navbar {
    position: relative;
    width: 95vw;
    padding: 20px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
}

.open {
    transition: all 0.3s ease-in-out;
}

.open .logo {
    color: white;
    font-weight: 600 !important;
}

.open .nav_btn {
    color: white;
    border-color: white;
}

.open .nav_btn:hover {
    background-color: white;
    color: black;
}

/* .open .hamburger {
    background-color: white;
}

.hamburger {
    cursor: pointer;
    height: 23px;
    width: 23px;
    padding: 8px;
    border: 1px solid black;
    transition: all 0.3s ease-in-out;
} */

.hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 8px;
    border: 1px solid black;
    transition: all 0.3s ease-in-out;
}

.hamburger svg {
    height: 25px;
    width: 25px;
    /* padding: 10px; */
    transition: all 0.3s ease-in-out;
}

.hamburger.cross{
    padding: 7px 5px 9px 11px;
    border-color: white;
}

.hamburger .line {
    transition: all 0.3s ease-in-out;
}

.hamburger.cross .line{
    stroke: white;
}

.hamburger.cross .line1 {
    transform: rotate(45deg);
    transform-origin: 10% 25%;
}

.hamburger.cross .line2 {
    opacity: 0;
}

.hamburger.cross .line3 {
    transform: rotate(-45deg);
    transform-origin: 20% 80%;
}

.top_right {
    width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: var(--text-xs);
    color: var(--dark);
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.nav_btn {
    padding: 8px 25px;
    margin-left: 30px;
    background-color: transparent;
    border: 1px solid var(--dark);
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    color: var(--dark);
    font-weight: 400;
    transition: 0.3s all ease-in-out;
}

.nav_btn span {
    font-size: var(--text-xs);
    margin-left: 6px;
}

.nav_btn svg path {
    transform: rotate(45deg);
    font-weight: 400;
}

.nav_btn:hover {
    background-color: var(--dark);
    color: var(--light);
}

.bottom_navbar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.bottom_navbar a {
    text-decoration: none;
    color: var(--gray);
    font-size: var(--text-xs);
    font-weight: 400;
    margin-right: 30px;
    position: relative;
}

.bottom_navbar a::after {
    content: '';
    position: absolute;
    top: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--dark);
    transition: width 0.5s ease;
}

.bottom_navbar a:hover::after {
    width: 90%;
}