.about_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    position: relative;
}

.about_page .header {
    text-align: left;
    margin-bottom: 3rem;
    width: 100%;
}

.about_page .page_title {
    margin-bottom: 5px;
}

.about_page .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: black;
    margin-bottom: 20px;
}

.about_page .subtitle {
    font-size: 18px;
    color: var(--gray);
}


.about_page .content_wrapper {
    width: 100%;
    margin-bottom: 5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
}

.about_container .reverse {
    flex-direction: row-reverse;
}

.about_container .content_wrapper.reverse .company_image {
    padding: 0 0 0 30px;
}

.about_container .content_wrapper.reverse .description {
    padding: 0 20px 0 0;
}

.about_page .content_wrapper .company_image {
    width: 40%;
    padding: 0 30px 0 0;
}

.about_page .content_wrapper .company_image img {
    width: 100%;
    height: auto;
    margin-top: 15px;
}

.about_page .content_wrapper .description {
    width: 60%;
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.about_page .content_wrapper .description .title {
    font-size: 35px;
}

.about_page .content_wrapper .description p {
    text-align: left;
}

.about_page .text_bold {
    font-size: 1.5rem;
    font-weight: 500;
}

.about_page .text_normal {
    font-size: var(--text-xs);
    text-align: left;
}

.about_page .about_container .why_us {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about_page .about_container .why_us .title {
    font-size: 35px;
    margin-bottom: 20px;
}

.about_page .about_container .why_us .sub_title {
    font-size: 18px;
    margin-bottom: 40px;
    color: var(--gray);
    width: 70%;
}

.about_page .about_container .why_us .why_us_desc {
    width: 80%;
    padding: 20px 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: 1px solid var(--gray);
}

.why_us_desc .desc_image {
    width: 10%;
    padding: 0 8px;
}

.why_us_desc .description {
    width: 90%;
    padding: 0 20px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.why_us_desc h2 {
    text-align: left;
}

.why_us_desc .desc_image img {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 992px) {
    .why_us_desc .desc_image {
        width: 15%;
    }
}

@media only screen and (max-width: 768px) {

    .about_page .about_container .why_us .why_us_desc {
        width: 90%;
    }

    .about_page .about_container .why_us .why_us_desc {
        flex-direction: column;
        align-items: center;
    }

    .why_us_desc .desc_image {
        width: 20%;
        margin-bottom: 20px;
    }

    .why_us_desc .description {
        width: 100%;
    }

    .about_page .content_wrapper {
        flex-direction: column;
        margin-bottom: 4rem;
    }

    .about_page .content_wrapper .company_image {
        width: 100%;
        padding: 0;
        margin-bottom: 15px;
    }

    .about_page .content_wrapper .description {
        width: 100%;
        padding: 0;
        gap: 10px;
    }

    .about_container .content_wrapper.reverse .company_image {
        padding: 0;
    }

    .about_container .content_wrapper.reverse .description {
        padding: 0;
    }

    .about_page .about_container .why_us .title {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 480px) {

    .about_page .content_wrapper .description .title {
        font-size: 30px;
    }

    .about_page .about_container .why_us .title {
        font-size: 30px;
    }

    .about_page .about_container .why_us .sub_title {
        width: 100%;
    }

    .why_us_desc .desc_image {
        width: 30%;
    }
}