.services_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 4rem; */
}

.services_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    position: relative;
}

.services_page .header {
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
}

.services_page .page_title {
    margin-bottom: 5px;
}

.services_page .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: black;
    margin-bottom: 20px;
}

.services_page .subtitle {
    font-size: 18px;
    color: var(--gray);
}


.services_page .wrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

/* Left Side */

.services_page .left_side {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.services_page .services_menu {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(40, 61, 88, .07);
}

.services_page .service {
    list-style: none;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 5px;
    color: var(--dark);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.services_page .service:hover {
    color: white;
}

.services_page .service.active {
    color: white;
}

.services_page .service::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--dark);
    z-index: -1;
    transition: all 0.3s ease-in-out;
}

.services_page .service:hover::after {
    width: 100%;
}

.services_page .service .icon_container {
    padding: 0 20px;
    border: 0.1px solid #e3e3e3;
    transition: all 0.2s ease-in-out;
    height: 100%;
    display: flex;
    align-items: center;
}

.services_page .icon_container svg {
    height: 10px;
    width: 10px;
}

.services_page .service.service.active .icon_container {
    transition: all 0s ease-in-out;
}

.services_page .service_name {
    padding: 15px 15px 15px 20px;
    flex: 1;
    text-align: left;
    margin-left: -1px;
    border: 0.1px solid #e3e3e3;
    border-left: none;
    position: relative;
}

.service.active .service_name {
    background-color: var(--dark);
}

.services_page .service:hover .icon_container {
    background-color: var(--gray);
    padding: 0 20px;
}

.services_page .service.active:hover .icon_container {
    padding: 0 20px;
}

.services_page .service.active .icon_container {
    background-color: var(--gray);
}

.services_page .about_us {
    width: 100%;
    padding: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 0 15px 0 rgba(40, 61, 88, .07);
}

.services_page .about_us h2 {
    margin-bottom: 20px;
}

.services_page .about_us img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.services_page .about_us p {
    text-align: left;
    color: var(--gray);
    line-height: 25px;
}

.services_page .help_wrapper {
    position: relative;
    width: 100%;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(40, 61, 88, .07);
    background-image: url(../../../public/assets/how-can-help.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.services_page .help_wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2d4a8a;
    opacity: 0.85;
}

.services_page .help {
    position: relative;
    width: 100%;
    padding: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0.5px dashed #ffffff47;
    z-index: 1;
}

.services_page .help .laptop {
    margin-bottom: 20px;
    width: 55px;
    height: 55px;
}

.services_page .help h2 {
    margin-bottom: 20px;
}

.services_page .help img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.services_page .help p {
    margin-bottom: 20px;
    text-align: left;
    line-height: 25px;
}

.services_page .help .contact {
    padding: 10px 0px;
}

.services_page .help .contact svg {
    margin: 0px 15px 0px 5px;
}

.services_page .help .contact a {
    text-decoration: none;
    color: white;
}

.services_page .help .phone {
    width: 100%;
    text-align: left;
    border-bottom: 0.5px dashed #ffffff47;
}

/* Right Side */

.services_page .right_side {
    width: 70%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.services_page .content_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 10px;
}

.services_page .content_top {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    justify-content: flex-start;
    align-items: flex-start;
}

.services_page .tab_menu {
    display: none;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: 1px solid #ccc;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-bottom: 20px;
    width: 100%;
}

.services_page .tab_menu::-webkit-scrollbar {
    display: none;
}

.services_page .tab {
    padding: 5px 0px;
    margin-left: 1.5rem;
    margin-bottom: -1px;
    color: var(--dark);
    cursor: pointer;
    word-spacing: -1px;
}

.services_page .tab:hover {
    background-image: linear-gradient(-270deg, #5cc0ee 0%, #5e64ef 25%, #c997d2 50%, currentColor 51%, currentColor 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
}

.services_page .tab.active {
    color: #5cc0ee;
    /* Text color of active tab */
    background-image: linear-gradient(90deg, #5cc0ee 0%, #5e64ef 25%, #c997d2 50%, currentColor 51%, currentColor 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(45deg, #5cc0ee, #5e64ef, #c997d2);
    border-image-slice: 1;
}


.services_page .content_wrapper img {
    width: 100%;
    margin-bottom: 30px;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.services_page .content_text h2 {
    text-align: left;
    margin-bottom: 20px;
}

.services_page .content_text p {
    text-align: left;
    margin-bottom: 20px;
    color: var(--gray);
}

.services_page .content_bottom {
    width: 100%;
    display: flex;
    padding: 30px 10px;
    justify-content: center;
    align-items: flex-start;
}

.services_page .content_bottom img {
    width: 50%;
}

.services_page .content_bottom .content_text {
    width: 50%;
    text-align: left;
    padding: 0px 10px 0px 0px;
}

.services_page .content_bottom .content_text li {
    padding: 10px 0;
    list-style: none;
}

.services_page .content_bottom .content_text svg {
    margin-right: 10px;
}

.services_page .content_bottom .button {
    margin-top: 30px;
}

.services_page .content_bottom a {
    text-decoration: none;
    color: white;
    background-color: black;
    text-align: center;
    padding: 10px 30px;
    transition: all 0.3s ease-in-out;
}

.services_page .content_bottom a:hover {
    background-color: white;
    color: black;
    border: 1px solid var(--dark);
}

@media only screen and (max-width: 992px) {
    .services_page .wrapper {
        flex-direction: column-reverse;
    }

    .services_page .right_side {
        width: 100%;
    }

    .services_page .left_side {
        width: 100%;
    }

    .services_page .tab_menu {
        display: flex;
        margin-bottom: 20px;
    }

    .services_page .tab {
        margin-left: 2rem;
        word-spacing: 0px;
        letter-spacing: 1px;
    }

    .services_page .service .icon_container {
        padding: 20px;
    }

    .services_page .service:hover .icon_container,
    .services_page .service.active:hover .icon_container {
        padding: 20px;
    }

    .services_page .about_us img {
        width: 50%;
    }

    .services_page .right_side {
        padding: 0px;
        margin-bottom: 30px;
    }

    .services_page .content_top {
        padding: 0px;
    }

    .services_page .content_wrapper {
        padding: 0 1px;
    }

    .services_page .services_menu {
        display: none;
        padding: 0 10px;
    }

    .services_page .content_bottom {
        flex-direction: column;
        align-items: center;
    }

    .services_page .content_bottom .content_text {
        width: 100%;
        margin-bottom: 30px;
    }

    .services_page .content_bottom img {
        width: 90%;
    }
}

@media only screen and (max-width: 480px) {
    .services_page .about_us {
        width: 100%;
        padding: 20px;
    }

    .services_page .tab {
        margin-left: 1.5rem;
        word-spacing: -1px;
        letter-spacing: 0px;
    }

    .services_page .services_menu {
        /* display: none; */
        padding: 0px 20px;
    }

    .services_page .content_bottom img {
        width: 95%;
    }

    .services_page .about_us img {
        width: 70%;
    }

}