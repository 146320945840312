.technology_section {
    width: 100%;
    margin: 3rem 0;
}

.technology_section .title {
    margin-bottom: 10px;
    font-size: 34px;
}

.technology_section .subtitle {
    color: var(--gray);
    font-size: 18px;
}

.technology_slider {
    width: 99%;
    margin-top: 3rem;
}

.technology_slider .swiper_outer_div,
.technology_slider .swiper_body {
    position: relative;
    height: 100%;
}

.technology_slider .swiper_body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.technology_slider .swiper {
    width: 100%;
    height: 100%;
    background-color: white !important;

}

.technology_slider .swiper-slide {
    /* width: 30% !important; */
    text-align: center;
    font-size: 18px;
    background: linear-gradient(180deg, rgba(244, 247, 251, 1) 0%, rgba(244, 247, 251, 0) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding: 40px 5px 20px 5px;
}

.swiper-button-prev::after {
    font-size: 24px !important;
    left: 20px !important;
    color: black !important;
    padding: 10px;
    border: 1px solid black;
    background-color: white;
}

.swiper-button-next::after {
    font-size: 24px !important;
    left: 20px !important;
    color: black !important;
    padding: 10px;
    border: 1px solid black;
    background-color: white;
}

.technology_slider .swiper-slide .technology_details {
    width: 100%;
    height: 100%;
}

.swiper-slide .technology_heading {
    font-size: 20px;
    margin-bottom: 2rem
}

.swiper-slide .technology_details .tech_row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.swiper-slide .technology_details .tech_row .tech_col {
    width: 33.3333%;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 50px;
}

.swiper-slide .tech_col .tech_img_container {
    padding: 0 15px;
}

.swiper-slide .tech_col img {
    height: 40px !important;
}

@media screen and (max-width: 1200px) {
    .swiper-slide .tech_col .tech_img_container {
        padding: 0 20px;
    }
}

@media screen and (max-width: 992px) {
    .swiper-slide .tech_col .tech_img_container {
        padding: 0 8px;
    }
}

@media screen and (max-width: 480px) {
    .swiper-slide .technology_details .tech_row .tech_col {
        padding: 5px 30px;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        font-size: 10px !important;
    }
}