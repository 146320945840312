.industries_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.industries_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    position: relative;
}

.industries_page .header {
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
}

.industries_page .page_title {
    margin-bottom: 5px;
}

.industries_page .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: black;
    margin-bottom: 20px;
}

.industries_page .subtitle {
    font-size: 18px;
    color: var(--gray);
}

.tabs_wrapper {
    width: 100%;
    margin-top: 30px;
}

/* src/TabMenu.css */

.industries_page .tab_menu {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    border-bottom: 1px solid #ccc;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.industries_page .tab_menu::-webkit-scrollbar {
    display: none;
}

.industries_page .tab {
    padding: 5px 0px;
    margin-left: 3rem;
    margin-bottom: -1px;
    color: var(--dark);
    cursor: pointer;
    letter-spacing: 2px;
}

.industries_page .tab:hover {
    background-image: linear-gradient(-270deg, #5cc0ee 0%, #5e64ef 25%, #c997d2 50%, currentColor 51%, currentColor 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
}

.industries_page .tab.active {
    color: #5cc0ee;
    /* Text color of active tab */
    background-image: linear-gradient(90deg, #5cc0ee 0%, #5e64ef 25%, #c997d2 50%, currentColor 51%, currentColor 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(45deg, #5cc0ee, #5e64ef, #c997d2);
    border-image-slice: 1;
}

.content_wrapper {
    display: flex;
    padding: 30px 10px;
    justify-content: space-between;
    align-items: center;
}

.tab_detail {
    width: 55%;
    text-align: left;
    padding: 40px 20px 40px 0px;
}

.tab_detail h2 {
    margin-bottom: 20px;
}

.tab_detail p {
    margin-bottom: 20px;
}

.tab_detail svg {
    margin-right: 10px;
}

.tab_detail li {
    padding: 10px 0px;
}

.tab_detail .button {
    margin-top: 30px;
}

.tab_detail a {
    text-decoration: none;
    color: var(--dark);
    text-align: center;
    padding: 10px 30px;
    border: 1px solid var(--dark);
    transition: all 0.3s ease-in-out;
}

.tab_detail a:hover {
    color: white;
    background-color: var(--dark);
}

.tab_image {
    width: 45%;
    height: auto;
    padding-top: 50px;
}

.tab_image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
    .content_wrapper {
        flex-direction: column;
    }

    .tab_detail {
        width: 100%;
        padding: 0;
        margin-bottom: 40px;
    }

    .tab_detail a {
        display: block;
    }

    .industries_page .tab {
        margin-left: 2rem;
    }

    .tab_image {
        width: 100%;
        padding-top: 0;
    }
}

@media only screen and (max-width: 425px) {
    .industries_page .tab {
        margin-left: 1rem;
    }

    .tab_detail a {
        font-size: 14px;
        padding: 10px 15px;
    }
}
