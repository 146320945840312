.contact_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact_container {
    width: 90%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    position: relative;
}

.contact_page .header {
    text-align: left;
    margin-bottom: 3rem;
    width: 100%;
}

.contact_page .page_title {
    margin-bottom: 5px;
}

.contact_page .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: black;
    margin-bottom: 20px;
}

.contact_page .subtitle {
    font-size: 18px;
    color: var(--gray);
}

.contact_wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.contact_details {
    margin-top: 40px;
    width: 40%;
    text-align: left;
}

.header {
    margin-bottom: 20px;
}

.contact_subheading {
    margin-bottom: 5px;
    color: #2d4a8a;
    font-size: 18px;
    font-weight: 600;
}

.contact_heading {
    margin-bottom: 20px;
}

.widget_wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 0;
    border-bottom: solid 0.5px #e9e9e9;
    margin-right: 50px;
}

.widget_icon {
    width: 70px;
}

.widget_icon svg {
    color: white;
    padding: 16px;
    background: var(--gray);
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.widget_content {
    width: calc(100% - 70px);
    padding: 0 5px;
}

.widget_content h3 {
    margin-bottom: 5px;
}

.widget_content h6 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.widget_content p {
    font-size: 15px;
}

.widget_content a {
    margin-bottom: 5px;
    text-decoration: none;
    color: var(--gray);
}

.border {
    display: block;
    width: 52px;
    height: 4px;
    border: 4px solid transparent;
    border-top-color: #2d4a8a;
}

.contact_form {
    padding: 40px 50px 40px 40px;
    width: 60%;
    text-align: left;
    background-color: #f1f3f9;
    border-radius: 10px;
}

.form_row {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.input_div {
    width: 50%;
    padding: 10px;
}

.input_div input {
    width: 100%;
    padding: 20px;
    border: none;
    font-size: 16px;
}

.text_box {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
}

.text_box textarea {
    width: 100%;
    max-width: 100%;
    padding: 25px;
    font-size: 16px;
    border: none;
}

form .button {
    margin-top: 30px;
    padding: 0 10px;
}

form .button input {
    cursor: pointer;
    text-decoration: none;
    color: var(--dark);
    text-align: center;
    padding: 10px 30px;
    background-color: white;
    font-size: 16px;
    border: 1px solid var(--dark);
    transition: all 0.3s ease-in-out;
}

form .button input:hover {
    color: white;
    background-color: var(--dark);
}

.location {
    width: 95%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.location h3 {
    text-align: left;
    margin-bottom: 10px;
}

.location_wrapper {
    width: 50%;
}

.location_inner {
    border: 1px solid black;
    position: relative;
}

.place-card {
    display: none !important;
}

.location iframe {
    width: 100%;
    height: 350px;
}

.location_content {
    width: 305px;
    height: 120px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 10px;
    left: 10px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.location_content h4{
    font-size: 14px;
    font-family: Roboto, Arial;
    margin-bottom: 10px;
}

.location_content p{
    font-size: 13px;
    font-family: Roboto, Arial;
    color: #5b5b5b;
    margin-bottom: 15px;
}

.location_content a{
    font-size: 12.5px;
    text-decoration: none;
    font-family: Roboto, Arial;
    color: #1a73e8;
    margin-bottom: 5px;
}

@media only screen and (max-width: 992px) {
    .contact_wrapper {
        flex-direction: column;
    }

    .contact_details {
        width: 100%;
        margin-bottom: 30px;
    }

    .contact_form {
        width: 100%;
        padding: 10px 0 50px 0;
    }

    .location {
        flex-direction: column;
    }

    .location_wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .form_row {
        flex-direction: column;
    }

    .input_div {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .contact_form {
        width: 100%;
        padding: 10px 0 30px 0;
    }

    .location iframe {
        height: 200px;
    }

    .location_content{
        width: 265px;
        height: 60px;
    }

    .location_content h4{
    margin: 0;
    }

    .location_content p{
    display: none;
    }

}

/* ThankYou Page */

.thankYou_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.thankYou_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    position: relative;
}

.thankYou_page .header {
    width: 80%;
    margin-top: 10rem;
    margin-bottom: 3rem;
    padding: 40px 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.25);
}

.thankYou_page .page_title {
    font-size: 35px;
    margin-bottom: 20px;
}

.thankYou_page .thankYou_img {
    width: 120px;
    margin-bottom: 30px;
}

.thankYou_page .subtitle {
    width: 38%;
    font-size: 22px;
    color: var(--gray);
}

.thankYou_page .home_btn {
    padding: 8px 20px;
    border: 1px solid var(--dark);
    border-radius: 30px;
    margin-top: 30px;
    text-decoration: none;
    background-color: var(--dark);
    color: var(--light);
    transition: 0.3s all;
}

.thankYou_page .home_btn:hover {
    background-color: var(--light);
    color: var(--dark);
}

.thankYou_page .saparator {
    margin-bottom: 3rem;
    border: none;
    border-top: 1px dashed #bbbbbb;
    width: 50%;
}

.thankYou_page .heading {
    font-size: 30px;
    color: var(--dark);
    margin-bottom: 30px;
}

.thankYou_page .logos_wrapper {
    margin-bottom: 30px;
}

.thankYou_page .logo_container {
    display: inline-flex;
    height: 45px;
    width: 45px;
    border-radius: 5px;
    margin: 0px 15px;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
}

.thankYou_page .logo_container:hover {
    background-color: var(--dark);
    transition: all 0.3s ease
}

.thankYou_page .logo {
    font-size: 35px;
    color: var(--dark);
}

.thankYou_page .logo_container:hover .logo {
    color: var(--light);
    transition: all 0.3s ease
}

@media only screen and (max-width: 1150px) {
    .thankYou_page .subtitle {
        width: 50%;
    }
}

@media only screen and (max-width: 992px) {
    .thankYou_page .page_title {
        font-size: 28px;
        margin-bottom: 15px;
    }

    .thankYou_page .thankYou_img {
        width: 110px;
        margin-bottom: 20px;
    }

    .thankYou_page .subtitle {
        width: 53%;
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .thankYou_page .header {
        margin-top: 7rem;
        padding: 40px 20px;
    }

    .thankYou_page .thankYou_img {
        width: 100px;
    }

    .thankYou_page .subtitle {
        width: 95%;
    }
}

@media only screen and (max-width: 480px) {
    .thankYou_page .header {
        width: 95%;
        margin-top: 6rem;
        padding: 25px 20px;
        box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.25);
    }

    .thankYou_page .page_title {
        font-size: 22px;
    }

    .thankYou_page .thankYou_img {
        width: 80px;
    }

    .thankYou_page .home_btn {
        margin: 2rem 0;
    }

    .thankYou_page .saparator {
        width: 70%;
        margin-bottom: 1rem;
    }

    .thankYou_page .heading {
        font-size: 25px;
        margin-bottom: 25px;
    }

    .thankYou_page .logo_container {
        height: 40px;
        width: 40px;
        margin: 0px 6px;
    }

    .thankYou_page .logo {
        font-size: 30px;
    }
}