.home_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
}

.hero_wrapper {
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero {
    width: 90%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    position: relative;
}

.hero_container {
    width: 100%;
    display: flex;
    margin-top: 4rem;
    align-items: flex-start;
    justify-content: flex-start;
}

.hero_title {
    width: 50%;
    text-align: left;
    font-size: var(--text-lg);
    /* font-family: "Orbitron", sans-serif; */
    /* font-family: "Tektur", sans-serif; */
    font-family: "Bruno Ace SC", sans-serif;
    /* font-family: "Big Shoulders Stencil Display", sans-serif; */
    font-weight: 400;
    line-height: normal;
    /* margin-top: 4rem; */
    padding-right: 20px;
}

.hero_img {
    width: 50%;
    overflow: hidden;
    /* height: 80vh; */
    object-fit: cover;
    /* margin-top: 3rem; */
    margin-bottom: 60px;
    border-radius: 5px;
}

.explore_now {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    margin-bottom: 30px;
}

.explore_now_wrapper {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.explore_now_wrapper::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--dark);
    transition: width 0.3s ease;
}

.explore_now_wrapper:hover::after {
    width: 0;
}

.explore_now_wrapper a {
    text-decoration: none;
    font-size: var(--text-xs);
    color: var(--dark);
}

.explore_now_wrapper:hover a,
.explore_now_wrapper:hover svg path {
    color: var(--gray);
}

@media only screen and (max-width: 480px) {

    .hero {
        margin-top: 20px;
    }

    .hero_title {
        font-size: 25px;
        margin-top: 25px;
    }

    .hero_img {
        margin-top: 25px;
        height: 40vh;
    }

    .explore_now_wrapper {
        width: 40%;
    }

    .explore_now {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 992px) {
    .hero_container {
        flex-direction: column;
        margin-top: 3rem;
    }

    .hero_title {
        width: 100%;
        padding: 0;
    }

    .hero_img {
        width: 100%;
        margin-top: 3rem;
    }
}

@media only screen and (max-width: 480px) {
    .hero_img{
        margin-top: 25px;
    }
}

@media only screen and (min-width: 480px) {
    .hero {
        width: 95%;
    }

    .hero_title {
        font-size: var(--text-md);
    }
}

@media only screen and (min-width: 768px) {
    .hero_title {
        font-size: 55px;
    }
}

@media only screen and (max-width: 768px) {
    .hero_container {
        margin-top: 0;
    }

    .hero_title {
        margin-top: 0px;
    }
}

/*****************************
    Flow Section
*****************************/

.flow {
    width: 100vw;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hr {
    width: 100%;
    height: 1px !important;
    background-color: var(--gray);
    border: none;
}

.work_flow {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
}

.flow::before,
.flow::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: var(--gray);
    position: absolute;
    left: 0;
}

.flow::before {
    top: 0;
}

.flow::after {
    bottom: 0;
}

.work_flow .scroll svg {
    margin-right: 5px;
}

@media only screen and (max-width: 480px) {
    .work_flow {
        padding: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .work_flow {
        width: 95%;
    }
}

.work_flow p {
    text-transform: uppercase;
}

.arrow_down path {
    margin-right: 20px;
}

/*****************************
    About Section
*****************************/

.about_section {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
}


.about_wrapper {
    width: 90%;
    display: flex;
}

.about_detail {
    width: 70%;
    margin-top: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 80px 0px 0px;
}

.about_detail p {
    font-size: var(--text-xs);
    color: var(--dark);
    text-align: left;
    padding-top: 20px;
}

.about_detail .hr {
    margin-top: 40px;
}

.about_detail h3 {
    font-size: var(--text-xs);
    text-align: left;
    font-weight: 500;
    margin-top: 3rem;
}

.round_btn {
    padding: 8px 10px;
    width: 20%;
    border: 1px solid var(--dark);
    border-radius: 30px;
    margin-top: 3rem;
    text-decoration: none;
    color: var(--dark);
    transition: 0.3s all;
}

.project_round_btn {
    margin: 3rem 0;
}

.round_btn svg {
    margin-left: 15px;
}

.round_btn:hover {
    background-color: var(--dark);
    color: var(--light);
}

@media only screen and (max-width: 480px) {
    .about_detail h3 {
        margin-top: 1.5rem;
    }

}

@media only screen and (max-width: 768px) {
    .count {
        width: auto;
    }

    .center_line {
        width: 15px;
    }


    .round_btn {
        padding: 5px 15px;
        width: auto;
    }

    .round_btn svg {
        margin-left: 10px;
    }
}

.about2 {
    position: relative;
}

.about2::before,
.about2::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: var(--gray);
    position: absolute;
    left: 0;
}

.about2::before {
    top: 0;
}

.about2::after {
    bottom: 0;
}

.about2 p {
    width: 50%;
}

.about2 .hr {
    margin-bottom: 40px;
}

.about2 h3 {
    margin-top: 0 !important;
    font-size: var(--text-sm);
}

@media only screen and (max-width: 480px) {

    .about2 h3 {
        margin-top: 0 !important;
        font-size: 20px;
    }

    .about2 p {
        width: 100%;
    }
}

/*****************************
    Scroll Animations
*****************************/

/* .heading-wrapper {
    width: 100vw;
    height: 50vh;
    display: flex;
    align-items: center;
    overflow: hidden;
}

#animated-heading {
    position: relative;
    left: -60%;
    font-size: 6rem;
    text-transform: uppercase;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: rgba(59, 55, 55, 0.7);
    text-shadow:
        -1px -1px 0 rgba(0, 0, 0, 0.5),
        1px -1px 0 rgba(0, 0, 0, 0.5),
        -1px 1px 0 rgba(0, 0, 0, 0.5),
        1px 1px 0 rgba(0, 0, 0, 0.5);
    transition: transform 0.1s ease-out;
    white-space: nowrap;
}

@media only screen and (max-width: 768px) {
    .heading-wrapper {
        display: none;
    }
} */

/*****************************
    Brands Section
*****************************/
.partners {
    width: 100vw;
}

.brands_container {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 20vh;
    margin-bottom: 30px;
    margin-top: 20px;
}

.brands {
    flex: 0 0 calc(100% / 5);
    padding: 20px;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    background-color: #ffffff;
    margin-right: 10px;
    animation: move-logos 3s linear infinite;
}

.brands img {
    object-fit: cover;
    width: 50%;
    height: 100%;
}

@keyframes move-logos {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}


/*****************************
    Projects Section
*****************************/
.projects {
    width: 95%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-left: auto;
}

.project_content {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.projects .about_wrapper {
    width: 100%;
}

.projects .about_detail {
    width: 100%;
    height: 360px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 0px;
    padding: 0px 0px 0px 0px;
    height: 70vh;
    border-left: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
}

.projects .about_detail.row_reverse {
    flex-direction: row-reverse !important;
}

.projects_image {
    width: 50%;
    height: 100%;
    position: relative;
}

.projects_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}

.projects_image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.projects_detail {
    width: 50%;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.projects_detail .detail_text {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
}

.detail_text p {
    padding-right: 40px;
}

.project_category {
    font-size: var(--text-xs);
}

.project_category span {
    font-weight: 600;
    margin-right: 10px;
}

.detail_text h4 {
    font-size: var(--text-sm);
    margin-top: 1.5rem;
}

.projects_detail .round_btn {
    width: 30%;
    margin-bottom: 40px;
    margin-top: 0.5rem;
}

/*****************************
    Process Section
*****************************/

.process {
    margin-bottom: 30px;
}

.process_details {
    padding: 0px 0px 0px 80px;
}

.process_header {
    display: flex;
    column-gap: 180px;
    align-items: flex-end;
}

.process_heading {
    color: var(--dark);
    font-size: 55px;
    margin: 0;
    font-weight: 400;
}

.process_sub_heading {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

.accordion {
    font-family: Ppneuemontreal Variable, sans-serif;
}

@media only screen and (max-width: 768px) {
    .process_details {
        padding: 0;
        margin-top: 30px;
    }

}

/*****************************
    Blogs Section
*****************************/

.blog {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--dark);
}

.blog_image {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.blog_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blogs .about_detail {
    padding: 0px 0px 0px 0px;
}

.blog_detail {
    display: flex;
    padding: 30px 20px 30px 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
}

.blog_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog_header h4 {
    font-size: var(--text-xs);
    font-weight: 500;
}

.blog_header p {
    font-size: 14px;
}

.blogs .round_btn {
    width: 40%;
}

.blog_desc p {
    margin-top: 1rem;
    font-size: 15px;
}

@media only screen and (max-width: 480px) {
    .blog p {
        padding-top: 0px;
    }

    .blog_header h4 {
        font-size: var(--body-text);
        font-weight: 500;
    }

    .blogs .round_btn {
        padding: 5px 15px;
        width: auto !important;
        margin-top: 1.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .blog {
        flex-direction: column;
    }

    .blogs .about_detail {
        margin-top: 30px;
    }

    .blog_image,
    .blog_detail {
        width: 100%;
    }

    .blogs .round_btn {
        width: 30%;
    }
}


/*****************************
    Industries Section
*****************************/

.industries_section {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.industry_title {
    font-size: var(--text-md);
    margin-top: 5rem;
}

@media only screen and (max-width: 992px) {

    .about_wrapper {
        width: 90%;
        flex-direction: column;
    }

    .about_detail {
        width: 100%;
        padding: 0px;
        margin-top: 0px;
    }

    .about_detail h3 {
        margin-top: 2rem;
    }

    .project_content {
        width: 100%;
    }

    .project_round_btn {
        margin-top: 1rem;
    }

    .process_details {
        padding: 0;
    }

    .projects {
        width: 100%;
        margin-left: 0;
    }

    .projects .about_wrapper {
        width: 90%;
    }

    .projects .about_detail {
        height: 430px;
    }

    .projects_detail .round_btn {
        width: 40%;
    }
}

@media only screen and (max-width: 768px) {
    /* .projects {
        width: 100%;
    } */

    .project_content {
        width: 100%;
    }

    .projects_image {
        width: 50%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .projects_detail {
        margin-top: 0px;
    }

    .projects .about_detail {
        align-items: stretch;
        height: 380px;
        border: 1px solid var(--dark);
    }

}

@media only screen and (max-width: 480px) {

    .projects .about_detail {
        flex-direction: column;
        height: 650px !important;
        border: 1px solid var(--dark);
    }

    .detail_text h4 {
        font-size: var(--text-xs);
    }

    .projects_image {
        width: 100% !important;
        height: 50% !important;
    }

    .projects_detail {
        width: 100%;
        padding: 0px 20px;
        order: 2;
    }

    .projects .about_detail.row_reverse {
        flex-direction: column !important;
    }

    .detail_text p {
        padding-right: 0px;
    }
}