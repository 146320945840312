.career_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not_found_or_loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no_job_post {
    width: 100%;
    padding-bottom: 50px;
    font-size: 20px;
}

.career_container {
    width: 90%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden !important;
    position: relative;
}

.career_page .header {
    text-align: left;
    margin-bottom: 3rem;
    width: 50%;
}

.career_page .page_title {
    margin-bottom: 5px;
}

.career_page .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: var(--dark);
    margin-bottom: 20px;
}

.career_page .subtitle {
    font-size: 18px;
    color: var(--gray);
}

.career_page .position_separator {
    width: 100%;
    margin-bottom: 2rem;
    border: none;
    border-top: 0.1px solid var(--gray);
}

.career_page .position_title {
    margin-bottom: 2rem;
}

.position_wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.category_wrapper {
    width: 25%;
    padding: 10px;
    text-align: left;
}

.category_wrapper .category {
    width: 100%;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px #dbdbdb;
    margin-top: 55px;
}

.category_wrapper .category .category_name {
    list-style: none;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
}

.category_name label {
    cursor: pointer;
}

.category_wrapper .category .check_box {
    width: 15px;
    height: 15px;
}

.category_wrapper .category .check_box[type='checkbox'] {
    accent-color: #18D2F2;
}

.category_wrapper p {
    font-size: 20px;
    margin-bottom: 20px;
}

.post_wrapper {
    width: 75%;
    padding: 10px 10px 10px 30px;
    text-align: left;
}

.position_wrapper,
.category_wrapper {
    margin-bottom: 70px;
}

.position_wrapper .top_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.position_wrapper .bar_title {
    width: 55%;
    align-self: flex-end;
    font-size: 20px;
    margin-bottom: 20px;
}

.position_wrapper .bar_icon {
    display: inline-block;
    margin-left: 30px;
    cursor: pointer;
    color: var(--secondary);
    font-size: 20px;
}

.position_wrapper .bar_icon.black {
    color: var(--dark);
}

.position_wrapper .total_jobs {
    display: inline-block;
}

.position_wrapper .search_wrapper {
    max-height: 35px;
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 30px;
    padding: 10px;
    border: 0.1px solid rgb(218, 218, 218);
    border-radius: 5px;
}

.position_wrapper .search_input {
    outline: none;
    border: none;
}

.position_wrapper .search_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: var(--gray);
    transition: all 0.2s ease-in-out;
}

.position_wrapper .search_wrapper:focus-within {
    border: 1px solid var(--dark);
}

.position_wrapper .search_icon:hover {
    color: var(--dark);
}

.position_wrapper .posts {
    width: 100%;
    text-align: left;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    align-items: flex-start;
    justify-content: center;
    margin-top: 35px;
    /* min-height: 100vh; */
}

.posts .post {
    position: relative;
    width: 31%;
    text-decoration: none;
    color: var(--dark);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #dbdbdb;
    transition: all 0.3s ease-in-out;
    margin-right: 10px;
}

.list_view .post {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.posts .post .featured {
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #3AD8B6;
    padding: 2px 5px;
    font-size: 12px;
    border-radius: 3px;
}

.post .job_title {
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #202D87;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
}

.list_view .job_title {
    width: 50%;
    font-size: 18px;
    text-align: left;
}

.post:hover .job_title {
    color: #0dc0e0;
}

.post .job_positions {
    font-size: 13px;
    color: var(--gray);
    margin-bottom: 15px;
}

.list_view .job_positions {
    width: 35%;
    font-size: 15px;
    text-align: right;
}

.post .job_separator {
    margin-bottom: 15px;
    width: 30%;
    border: none;
    border-top: 2px solid var(--dark);
    transition: transform 0.3s ease-in-out;
}

.list_view .job_separator {
    display: none;
}

.post:hover .job_separator {
    border-color: var(--secondary);
    transform: scale(1.3);
}

.post .job_category {
    font-size: 16px;
    color: var(--dark);
    margin-bottom: 10px;
}

.list_view .job_category {
    width: 50%;
    font-size: 17px;
    text-align: left;
}

.post .job_location {
    font-size: 16px;
    color: var(--gray);
    margin: 0;
}

.list_view .job_location {
    font-size: 17px;
    width: 40%;
    text-align: right;
}

/*---------------------*/
/* Job Description Page */
/*---------------------*/

.job_desc_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.job_desc_page .desc_container {
    width: 90%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden !important;
    position: relative;
}

.job_desc_page .header {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.job_desc_page .header .title {
    font-size: 40px;
    text-align: left;
    margin-bottom: 1rem;
}

.job_desc_page .header p {
    text-align: left;
}

.job_desc_page .header .category {
    font-size: 18px;
    color: var(--gray);
    margin-right: 10px;
}

.job_desc_page .header .location {
    font-size: 18px;
    color: grey;
    margin-left: 10px;
}

.job_desc_page .apply_btn {
    margin-right: 30px;
    padding: 10px 15px;
    background-color: var(--secondary);
    color: var(--light);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px black;
    transition: all 0.3s ease;
}

.job_desc_page .apply_btn:hover {
    background-color: #0c98b0;
}

.desc_container .header_separator {
    width: 100%;
    border: none;
    border-top: 0.1px solid var(--gray);
}

.job_desc_page .content_wrapper {
    width: 100%;
    padding: 10px 10px 10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.job_desc_page .content_wrapper .content {
    width: 75%;
    padding: 50px 10px 10px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
}

.job_desc_page .content .job_data {
    text-align: left;
}

.job_desc_page .content .job_data svg {
    margin-right: 5px;
    width: 8px;
}

.job_desc_page .content .sub_title {
    font-size: 30px;
    color: var(--gray);
    margin-bottom: 1rem;
}

.job_desc_page .content .data {
    font-size: 18px;
    color: var(--dark);
    margin-bottom: 1rem;
}

.job_desc_page .content_wrapper .about_us {
    width: 25%;
    padding: 50px 10px 10px 10px;
    text-align: left;
}

.job_desc_page .content_wrapper .about_us .heading {
    color: var(--dark);
    margin-bottom: 20px;
}

.job_desc_page .content_wrapper .about_us .para {
    font-size: 16px;
    color: var(--gray);
}

.job_desc_page .logos_wrapper {
    margin-bottom: 30px;
}

.job_desc_page .logo_container {
    display: inline-flex;
    height: 35px;
    width: 35px;
    border-radius: 5px;
    margin: 0px 5px;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
}

.job_desc_page .logo_container:hover {
    background-color: var(--secondary);
    transition: all 0.3s ease
}

.job_desc_page .logo {
    font-size: 25px;
    color: var(--secondary);
}

.job_desc_page .logo_container:hover .logo {
    color: var(--light);
    transition: all 0.3s ease
}


/*---------------------*/
/* Job Apply Page */
/*---------------------*/

.apply_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.apply_page .desc_container {
    width: 90%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden !important;
    position: relative;
}

.apply_page .header {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.apply_page .header .title {
    font-size: 35px;
    text-align: left;
    margin-bottom: 1rem;
}

.apply_page .header p {
    text-align: left;
}

.apply_page .header .category {
    font-size: 18px;
    color: var(--gray);
    margin-right: 10px;
}

.apply_page .header .location {
    font-size: 18px;
    color: var(--gray);
    margin-left: 10px;
}

.apply_page .job_form {
    width: 70%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
}

.apply_page .job_form .form_title {
    margin-bottom: 1rem;
}

/* ------------------ */
.form_group {
    margin-bottom: 20px;
}

.form_group label {
    display: block;
    margin-bottom: 5px;
}

.form_group input,
.form_group select,
.form_group textarea {
    width: 100%;
    padding: 15px 10px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.form_group input:focus,
.form_group select:focus,
.form_group textarea:focus {
    border: 1px solid var(--dark);
}

.error_line {
    color: red;
    font-size: 12px;
}

.drop_zone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 100px;
    transition: all 0.3s ease;
}

.drop_zone:hover {
    background-color: #f6f6f6;
    border: 2px dashed var(--gray);
}

.drop_zone .drop_btn {
    background-color: #dddddd;
    color: black;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button {
    background-color: white;
    color: var(--dark);
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 2px solid var(--gray);

}

button:hover {
    background-color: var(--dark);
    color: White;
    border-color: var(--dark);
}

@media only screen and (max-width: 992px) {

    .career_page .page_title,
    .career_page .position_title {
        font-size: 25px;
    }

    .position_wrapper {
        flex-direction: column;
        row-gap: 30px;
    }

    .career_page .header {
        width: 75%;
    }

    .category_wrapper {
        width: 100%;
    }

    .position_wrapper .top_bar {
        margin-bottom: 20px;
    }

    .post_wrapper {
        width: 100%;
        padding: 10px;
    }

    .position_wrapper .bar_title {
        width: 45%;
    }

    .posts .post {
        width: 48%;
    }

    .list_view .post {
        width: 100%;
    }

    .category_wrapper p,
    .position_wrapper .bar_title {
        font-size: 18px;
    }

    .job_desc_page .content_wrapper {
        flex-direction: column;
        row-gap: 30px;
    }

    .job_desc_page .content_wrapper .content {
        width: 100%;
    }

    .job_desc_page .content_wrapper .about_us {
        width: 100%;
        padding: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .career_page .header {
        width: 100%;
    }

    .position_wrapper .top_bar {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .position_wrapper .bar_icon {
        margin-left: 20px;

    }

    .position_wrapper .search_wrapper {
        width: 100%;
        margin: 0;
    }

    .apply_page .job_form {
        width: 100%;
    }

    .job_desc_page .header {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 30px;
    }

    .job_desc_page .header .category,
    .job_desc_page .header .location,
    .job_desc_page .apply_btn {
        margin: 0;
    }
}

@media only screen and (max-width: 480px) {
    .position_wrapper .posts {
        justify-content: center;
    }

    .position_wrapper .bar_icon_container {
        display: none;
    }

    .position_wrapper .top_bar {
        align-items: flex-start;
        row-gap: 0px;
    }

    .posts .post {
        width: 98%;
    }

    .post_wrapper {
        width: 100%;
        padding: 10px 0 30px 0;
    }
}