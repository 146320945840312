.customer_section {
    width: 100%;
    margin: 3rem 0;
    overflow: hidden;
}

.customer_container {
    padding: 40px 0 10px 0;
    display: flex;
}

.customer_section .title{
    margin-bottom: 10px;
    font-size: 34px;
}

.customer_section .subtitle{
    color: var(--gray);
    font-size: 18px;
    width: 75%;
    margin: 0 auto;
}

.customer_section .logo_container{
    width: 270px;
    padding: 0 70px;
    margin: 0 10px;
}

.customer_logo {
    width: 100%;
    height: auto;
}

.logo_wrapper {
    display: flex;
    align-items: center;
    animation: swipe 20s linear infinite backwards;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}

@media  only screen and (max-width: 992px) {
    .customer_section .logo_container{
        width: 200px;
        padding: 0 50px;
        margin: 0 10px;
    }
}

@media  only screen and (max-width: 768px) {
    .customer_section .logo_container{
        width: 140px;
        padding: 0 30px;
        margin: 0 10px;
    }
}

@media  only screen and (max-width: 480px) {
    .customer_section .logo_container {
        width: 150px;
        padding: 0 25px;
        margin: 0 10px;
    }
}