:root{
  --light : #FFFFFF;
  --dark : #1E2124;
  --gray : #767676;
  --secondary : #22bfda;
  --text-lg : 3rem;
  --text-md : 2.7rem;
  --text-sm : 1.8rem;
  --text-xs : 1.1rem;
  --body-text : 1rem;
  --nav-text : 0.8rem;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
