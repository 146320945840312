.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 100vw;
}

.footer_wrapper {
    width: 95%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.footer_line {
    width: 100%;
    border-top: 1px solid black;
}

.footer_title {
    text-align: left;
    font-size: 40px;
    margin-top: 15px;
}

.footer_content {
    width: 100%;
    height: 200px;
    background-color: #f3f3f391;
    margin: 30px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer_content .saperator {
    border-left: 1px solid black;
    height: 80%;
    align-self: center;
}

.footer_content .lets_chat {
    width: 33.333%;
    text-align: left;
    padding: 20px 40px;
}

.footer_content .lets_chat h1 {
    margin-bottom: 15px;
}

.footer_content .lets_chat .contact {
    padding: 10px 0;
    margin-left: 10px;
}

.footer_content .lets_chat .contact svg {
    margin: 0px 15px 0px 5px;
}

.footer_content .lets_chat .contact a {
    text-decoration: none;
    color: black;
}

.footer_content .lets_chat .phone {
    width: 75%;
    text-align: left;
    border-bottom: 0.5px dashed #00000047;
}

.footer_content .nav {
    width: 33.333%;
    text-align: left;
    padding: 20px;
}

.footer_content .nav ul {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    height: 170px;
    margin-left: 20px;
}

.footer_nav_item {
    width: 50%;
    text-align: start;
    list-style: none;
    margin-bottom: 15px;
}

.footer_nav_link {
    text-decoration: none;
    color: black;
    font-weight: 600;
    letter-spacing: 2px;
}

.footer_nav_item svg {
    top: 4px;
    left: 0;
    height: 15px;
    width: 0px;
    transition: 0.3s ease-in-out;
}

.footer_nav_link:hover svg {
    width: 9px;
    margin-right: 3px;
    transition: 0.3s ease-in-out;
}

.footer_content .follow_us {
    width: 33.333%;
    text-align: left;
    padding: 20px;
}

.footer_content .follow_us h1 {
    margin-bottom: 15px;
}

.footer .logo_container {
    display: inline-flex;
    height: 35px;
    width: 35px;
    border-radius: 5px;
    margin: 0px 5px;
    transition: all 0.5s ease;
    align-items: center;
    justify-content: center;
}

.footer .logo_container:hover {
    background-color: black;
    transition: all 0.3s ease
}

.footer .logo {
    font-size: 25px;
}

.footer .logo_container:hover .logo {
    color: white;
    transition: all 0.3s ease
}

.footer_end {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_end p {
    font-size: 18px;
}

@media only screen and (max-width: 992px) {
    .footer_wrapper {
        width: 90%;
    }

    .footer_title {
        font-size: 30px;
    }

    .footer_content {
        height: 160px;
        margin: 20px 0;
    }

    .footer_content .nav {
        width: 40%;
    }

    .footer_content .nav ul {
        height: 140px;
    }

    .footer_content .lets_chat {
        width: 33%;
        padding: 20px;
    }

    .footer_content .lets_chat h1 {
        margin-bottom: 5px;
        font-size: 20px;
    }

    .footer_content .lets_chat .phone {
        width: 90%;
    }

    .footer_content .follow_us {
        width: 27%;
    }

    .footer_content .follow_us h1 {
        font-size: 20px;
    }

    .footer .logo_container {
        height: 25px;
        width: 25px;
        margin: 0px 2px;
    }

    .footer_nav_item {
        width: 60%;
        margin-bottom: 10px;
    }

    .footer .logo {
        font-size: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .footer_content .saperator {
        border-top: 0.5px solid #0000001c;
        border-left: 0;
        width: 95%;
    }

    .footer_content {
        flex-direction: column;
        height: auto;
    }

    .footer_content .nav ul {
        width: 70%;
        margin: 0;
    }

    .footer_content .nav {
        width: 100%;
    }

    .footer_content .lets_chat {
        width: 100%;
    }

    .footer_content .lets_chat .phone {
        width: 45%;
        border-bottom: 0.5px dashed #0000001c;
    }

    .footer_content .follow_us {
        width: 100%;
    }

    .footer .logo_container {
        height: 30px;
        width: 30px;
        margin: 0px 5px;
    }

    .footer .logo {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .footer_content .nav ul {
        height: auto;
    }

    .footer_nav_item {
        margin-bottom: 15px;
    }

    .footer_content .lets_chat .phone {
        width: 65%;
    }
}

/* .footer_top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.footer_title {
    font-family: FFF Acid Grotesk, sans-serif;
    color: var(--light);
    margin: 0px;
    font-size: 55px;
    flex: 1 1;
    text-align: start;
    font-weight: 500;
    letter-spacing: -2px;
}

.btn_container {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 15px;
}

.service_btn {
    font-family: FT Polar Semi Mono, sans-serif;
    padding: 8px 20px;
    background-color: var(--gray);
    border-radius: 25px;
    color: var(--light);
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

.service_btn:hover {
    box-shadow: inset 0px 0px 9px 0px var(--secondary) !important;
    transition: 0.2s ease-in-out;
}

.demo_btn {
    font-family: FT Polar Semi Mono, sans-serif;
    padding: 8px 20px;
    color: var(--light);
    border-radius: 25px;
    background-color: var(--secondary);
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

.demo_btn:hover {
    box-shadow: inset 0px 0px 9px 0px var(--dark) !important;
    transition: 0.2s ease-in-out;
}

.footer_bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 85px;
}

.left_side {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 20px;
    flex-grow: 1;
}

.right_side {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
}

.footer_logo {
    width: 60px;
}

.footer_bottom .footer_subtitle {
    font-family: FFF Acid Grotesk, sans-serif;
    color: var(--gray);
    max-width: 185px;
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
    margin-left: 20px;
    text-align: start;
    line-height: 25px;
}

.footer_nav_container {
    margin: 0px;
    padding: 0px;
}

.footer_nav_item {
    text-align: start;
    list-style: none;
    margin-bottom: 10px;
}

.footer_nav_link {
    text-decoration: none;
    color: var(--light);
    font-weight: 500;
    transition: 0.2s ease-in-out;
}

.footer_nav_link:hover {
    color: var(--secondary);
    transition: 0.2s ease-in-out;
}

.lets_chat {
    margin: 0px;
    padding: 0px;
    color: var(--light);
    font-size: 18px;
    font-weight: 500;
}

.footer .logo_container {
    text-align: center;
    padding: 4px 0 0 4px;
    vertical-align: middle;
    display: inline-block;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    margin: 0px 1px;
    transition: all 0.5s ease;
}

.footer .logo_container:hover {
    background-color: var(--light);
    transition: all 0.3s ease
}

.footer .logo {
    color: var(--secondary) !important;
}

.footer .logo_container:hover .logo {
    color: var(--dark) !important;
    transition: all 0.3s ease
}

@media only screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 20px;
    }

    .footer_wrapper {
        flex-direction: column;
        border-radius: 25px;
        padding: 20px;
    }

    .footer_top {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer_title {
        font-size: 30px;
        flex: 1 1;
        letter-spacing: 0px;
        margin-bottom: 25px;
    }

    .btn_container {
        width: 100%;
    }

    .demo_btn, .service_btn {
        padding: 16px;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
        width: 100%;
    }

    .left_side {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer_bottom {
        flex-direction: column;
        margin-top: 50px;
    }

    .footer_bottom .footer_subtitle {
        margin: 10px 0px 25px 0px;
        font-weight: 600;
        max-width: 200px;
    }

    .right_side {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer_nav_container {
        margin: 0px;
        margin-bottom: 30px;
    }

    .footer_nav_item {
        font-size: 20px;
    }

    .footer .logo_container {
        margin: 0px 5px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .footer {
        flex-direction: column;
        padding:20px;
    }

    .footer_wrapper {
        flex-direction: column;
        border-radius: 25px;    
        padding: 60px;
    }

    .footer_title {
        font-size: 55px;
        flex: 1 1;
        letter-spacing: 0px;
        margin-bottom: 25px;
    }

    .btn_container {
        width: 100%;
    }

    .demo_btn, .service_btn {
        padding: 14px 25px;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .footer_bottom {
        flex-direction: column;
        margin-top: 50px;
    }

    .left_side {
        margin-bottom: 35px;
    }

    .footer_bottom .footer_subtitle {
        margin-left: 15px;
        font-weight: 600;
        max-width: 200px;
    }

    .footer_nav_item {
        font-size: 20px;
    }

    .footer .logo_container {
        margin: 0px 5px;
    }

} */