.project_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-top: 4rem; */
}

.project_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden !important;
    position: relative;
    /* background-color: #f5f5f5; */
}

.project_page .header {
    text-align: left;
    margin-bottom: 1rem;
}

.project_page .page_title {
    margin-bottom: 5px;
}

.project_page .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: black;
    margin-bottom: 20px;
}

.project_page .subtitle {
    font-size: 18px;
    color: var(--gray);
}

.project_page .hero_title {
    width: 100%;
    text-align: left;
    font-size: var(--text-lg);
    font-family: "Bruno Ace SC", sans-serif;
    font-weight: 400;
    line-height: normal;
    /* margin-top: 4rem; */
}

/* Hero Section */

.hero_section {
    height: 60vh;
    width: 100vw;
    margin-bottom: 2rem;
    background-image: url(../../../public/assets/title-image.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.hero_section .hero_wrapper {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
}

.hero_section .hero_wrapper h1 {
    font-size: var(--text-md);
    font-family: "Bruno Ace SC", sans-serif;
}

.hero_section a,
.hero_section span {
    font-size: 20px;
    text-decoration: none;
    color: Black;
}

.hero_section b {
    margin: 0 5px;
}

.hero_section a svg {
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
}

@media only screen and (max-width: 992px) {
    .hero_section {
        height: 35vh;
    }
}

@media only screen and (max-width: 992px) {
    .hero_section {
        height: 30vh;
    }
}

/*  */
/*  */

.project_page .wrapper {
    width: 100%;
    margin: 30px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.project_page .project_wrapper {
    width: 33.3333%;
    padding: 5px;
    height: 310px;
    margin-bottom: 30px;
}

.project_page .project {
    height: 280px;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
    position: relative;
    transition: all 0.5s ease-in;
}

.project::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

.project_page .project .content {
    text-align: left;
    bottom: -120px;
    left: 0;
    position: absolute;
    background-color: #2D4A8A;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 40%;
    margin-left: 12.5%;
    z-index: 2;
    opacity: 0;
    transition: all 0.8s;
    padding: 20px;
    color: white;
}

.project_page .project .content p {
    margin-bottom: 5px;
    color: var(--light);
}

.project_page .project .content h2 {
    color: var(--light);
    font-size: 20px !important;
    text-align: center;
    font-weight: bold;
}

.project_page .project:hover .content {
    bottom: 20px;
    opacity: 1;
}

.project_page .project img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    transition: all 0.5s ease;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
}

.project_page .project:hover img {
    transform: scale(1.2);
    transition: transform 3s ease, opacity 0.5s ease;
    opacity: 0.6;
}


@media only screen and (max-width: 992px) {
    .project_page .project_wrapper {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .project_page .project_wrapper {
        width: 100%;
        height: 45vh;
    }

    .project_page .project {
        height: 45vh;
    }

    .project_page .project .content {
        bottom: 20px;
        opacity: 1;
    }

    .project_page .project img {
        opacity: 0.7;
    }
}

@media only screen and (max-width: 480px) {
    .project_page .project_wrapper {
        padding: 10px;
        height: 30vh;
        margin-bottom: 50px;
    }

    .project_page .project {
        height: 30vh;
    }

    .project_page .project .content{
        bottom: 10px;
        height: 45%;
    }

    .project_page .project .content h2{
        line-height: 30px;
    }
}

@media only screen and (max-width: 360px) {
    .project_page .project .content {
        padding: 10px 20px;
    }

    .project_page .project .content p {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .project_page .project .content h2 {
        font-size: 18px;
    }
}