.card_section{
    margin-bottom: 30px;
}

.card_section .about_wrapper {
    display: block;
}

.card_section .about_title {
    margin-bottom: 30px;
}

/* CardSlider */

.cardslider_section {
    width: 100%;
    margin: 3rem 0;
}

.cardslider_section .title {
    margin-bottom: 10px;
    font-size: 34px;
}

.cardslider_section .subtitle {
    color: var(--gray);
    font-size: 18px;
}

.cardslider_section .cards_slider {
    width: 99%;
    margin-top: 3rem;
}

.cardslider_section .cards_slider .swiper_outer_div,
.cardslider_section .cards_slider .swiper_body {
    position: relative;
    height: 100%;
}

.cardslider_section .cards_slider .swiper {
    position: static;
}

.cardslider_section .cards_slider .swiper_body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.cardslider_section .cards_slider .swiper {
    width: 100%;
    height: 100%;
    background-color: white !important;

}

.cardslider_section .swiper-button-prev{
    top: -40px;
    right: 70px;
    left: auto;
    width: 40px;
}

.cardslider_section .swiper-button-prev::after {
    width: 100%;
    font-size: 16px !important;
    left: 20px !important;
    color: black !important;
    padding: 10px;
    background-color: rgb(232 232 232);
    border: none;
    border-top-left-radius: 40%;
    border-bottom-left-radius: 40%;
    transition: all 0.3s;
}

.cardslider_section .swiper-button-prev:hover::after {
    background-color: rgb(222, 220, 220);
}

.cardslider_section .swiper-button-next{
    top: -40px;
    right: 5px;
    left: auto;
    width: 40px;

}

.cardslider_section .swiper-button-next::after {
    width: 100%;
    font-size: 16px !important;
    left: 20px !important;
    color: black !important;
    padding: 10px;
    background-color: rgb(232 232 232);
    border: none;
    border-top-right-radius: 40%;
    border-bottom-right-radius: 40%;
    transition: all 0.3s;
}

.cardslider_section .swiper-button-next:hover::after {
    background-color: rgb(222, 220, 220);
}

.cardslider_section .card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.4s;
    text-decoration: none;
    height: 460px;
    width: 100%;
}

.cardslider_section .card_content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    column-gap: 1rem;
    height: 100%;
    padding: 40px 30px 10px 30px;
    z-index: 2;
}

.cardslider_section .card_content h1 {
    color: black;
    font-size: var(--text-md);
    text-align: left;
    transition: all 0.5s ease-in-out;
}

.cardslider_section .card_content p {
    color: black;
    font-size: 22px;
    text-align: left;
    margin-top: 8rem;
    transition: all 0.5s ease-in-out;
}

.cardslider_section .card_content .arrow_container {
    text-align: right;
    margin-top: 10px;
}

.cardslider_section .card_content .arrow_container svg {
    width: 60px;
    height: 60px;
    color: black;
    transform: rotate(45deg);
    transition: all 0.5s ease-in-out;
}

.cardslider_section .card:hover .card_content h1,
.cardslider_section .card:hover .card_content p {
    color: white;
}

.cardslider_section .card:hover .card_content .arrow_container svg {
    transform: rotate(0deg);
    color: white;
}

.cardslider_section .card_1 {
    background-image: url(../../../public/assets/hero.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cardslider_section .card_2 {
    background-image: url(../../../public/assets/hero.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cardslider_section .card_3 {
    background-image: url(../../../public/assets/hero.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.cardslider_section .card_4 {
    background-image: url(../../../public/assets/hero.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cardslider_section .card::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    /* background-color: #fee715ff; */
    transition: 0.5s linear;
    z-index: 1;
    opacity: 1;
    visibility: visible;
}

.cardslider_section .card_1::before {
    background-color: #FCBA04;
}

.cardslider_section .card_2::before {
    background-color: #76D7CD;
}

.cardslider_section .card_3::before {
    background-color: #FF6151;
}

.cardslider_section .card_4::before {
    background-color: #596fff;
}

.cardslider_section .card:hover::before {
    height: 0%;
    opacity: 1;
    visibility: visible;
}

@media only screen and (max-width: 992px) {
    .cardslider_section .card_content{
        padding: 25px 10px 10px 20px;
    }
    .cardslider_section .card_content h1{
        font-size: 30px;
    }
}
