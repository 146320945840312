.section_title {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.section_title .count {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.section_title .center_line {
    width: 100px;
    margin: 0px 5px;
    height: 2px;
    background-color: var(--dark);
}

.section_title h2 {
    font-size: var(--text-md);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2rem;
    width: 70%;
    text-align: left;
    line-height: 3.5rem;
}

@media only screen and (max-width: 992px) {
    .section_title {
        width: 70%;
        margin-bottom: 40px;
    }

    .section_title .count {
        width: 10%;
    }
}

@media only screen and (max-width: 768px) {

    .section_title .count {
        width: 15%;
    }
}

@media only screen and (max-width: 550px) {
    .section_title .count {
        width: 20%;
    }
}

@media only screen and (max-width: 480px) {
    .section_title {
        width: 100%;
    }

    .section_title h2 {
        font-size: 35px;
        width: 90%;
    }

    .section_title .count {
        width: 18%;
    }
}