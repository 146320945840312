.accordion_item {
    position: relative;
    border-bottom: 1px solid var(--dark);
    transition: all 0.3s ease-in-out;
}

.accordion_item::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--dark);
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.5s ease-in-out;
}

.accordion_item:hover::after {
    transform: scaleX(1);
}

.accordion_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 10px 0px;
    cursor: pointer;
}

.heading_wrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
}

/* Id */

.accordion_id {
    color: var(--dark);
    margin: 0;
    font-weight: 500;
    position: relative;
    overflow: hidden;
}

.accordion_id_abs {
    position: absolute;
    left: 0;
    top: 0;
}

.accordion_id_n1 {
    transform: translateY(0%);
    display: inline-block;
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_id_n1 {
    transform: translateY(-100%);
}

.accordion_id_n2 {
    transform: translateY(0%);
    display: inline-block;
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_id_n2 {
    transform: translateY(100%);
}

.accordion_id_m1 {
    display: inline-block;
    transform: translateY(100%);
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_id_m1 {
    transform: translateY(0%);
}

.accordion_id_m2 {
    display: inline-block;
    transform: translateY(-100%);
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_id_m2 {
    transform: translateY(0%);
}

/* Title */

.accordion_title {
    color: var(--dark);
    margin: 0;
    font-size: 30px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
}

.accordion_title_abs {
    position: absolute;
    left: 0;
    top: 0;
}

.accordion_title_n1 {
    transform: translateY(0%);
    display: inline-block;
    margin-right: 5px;
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_title_n1 {
    transform: translateY(-100%);
}

.accordion_title_n2 {
    transform: translateY(0%);
    display: inline-block;
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_title_n2 {
    transform: translateY(100%);
}

.accordion_title_m1 {
    display: inline-block;
    margin-right: 5px;
    transform: translateY(100%);
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_title_m1 {
    transform: translateY(0%);
}

.accordion_title_m2 {
    display: inline-block;
    transform: translateY(-100%);
    transition: all 0.4s ease-in-out;
}

.accordion_header:hover .accordion_title_m2 {
    transform: translateY(0%);
}

.accordion_toggle {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
}

.accordion_content {
    padding: 0px 20px 20px 0px;
    text-align: start;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.accordion_content p {
    font-size: 14px;
    padding: 10px;
}

.top_content {
    margin-top: 10px;
}

@media only screen and (max-width: 992px) {
    .process_header {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 767px) {

    .process {
        padding: 80px 20px;
    }

    .accordion_wrapper {
        justify-content: center;
    }

    .accordion {
        width: 100%;
    }

    .process_header {
        column-gap: 0px;
        justify-content: space-between;
    }

    .process_heading {
        font-size: 45px;
    }

    .accordion_header {
        padding: 15px 0px 10px 0px;
    }

    .accordion_title {
        font-size: 25px;
    }

}

@media only screen and (max-width: 575px) {
    .process {
        padding: 70px 20px;
    }

    .process_header {
        flex-direction: column;
        align-items: flex-start;
    }

    .process_heading {
        font-size: 45px;
        margin-bottom: 10px;
    }

    .accordion_title {
        font-size: 22px;
    }

    .accordion_content {
        padding: 0px 20px 10px 0px;
    }
}

@media only screen and (max-width: 361px) {
    .process {
        padding: 50px 5px;
    }

    .accordion_title {
        font-size: 20px;
    }
}