.casestudies_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.casestudies_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    position: relative;
}

.casestudies_page .header {
    text-align: left;
    margin-bottom: 1rem;
}

.casestudies_page .page_title {
    margin-bottom: 5px;
    color: var(--gray);
}

.casestudies_page .page_title b {
    color: var(--dark);
}

.casestudies_page .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: black;
    margin-bottom: 20px;
}

.casestudies_page .subtitle {
    width: 90%;
    font-size: 18px;
    color: var(--gray);
}

.casestudies_page .card_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    margin: 40px 0px;
}

.casestudies_page .case_study_card {
    position: relative;
    width: calc(40% - 20px);
    background: rgba(255, 255, 255, 0.9);
    color: var(--dark);
    text-decoration: none;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.casestudies_page .case_study_card img {
    width: 100%;
    object-fit: cover;
}

.casestudies_page .case_study_card .case_study_badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    backdrop-filter: blur(5px);
}

.casestudies_page .case_study_card .case_study_content {
    padding: 20px;
    text-align: left;
}

.casestudies_page .case_study_card .company_name {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
}

.casestudies_page .case_study_card .title {
    font-size: 16px;
    margin: 10px 0;
}

.casestudies_page .case_study_card .tags {
    text-decoration: underline;
    margin: 10px 0;
}

.casestudies_page .case_study_card .description {
    font-size: 14px;
    color: #555;
    margin: 10px 0px !important;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.casestudies_page .case_study_card:hover {
    transform: scale(1.05);
}

.casestudies_page .case_study_card:hover .read_more_circle {
    opacity: 1;
}

.casestudies_page .read_more_circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background: white;
    color: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.casestudies_page .image_container {
    position: relative;
    width: 100%;
}

@media (max-width: 768px) {
    .casestudies_page .case_study_card {
        width: calc(100% - 20px);
    }
}

.loading {
    width: 100%;
    height: 100vh;
    text-align: center;
    display: grid;
    place-items: center;
}

.cases_container {
    width: 100%;
    margin-bottom: 20px;
}

.cases_container p {
    line-height: 1.6;
}

.items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    border: 2px solid var(--gray);
}

.details {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.details img {
    width: 120px;
}

.team {
    padding-right: 30px;
}

.loading_btn {
    width: 150px;
    padding: 8px;
    margin-top: 30px;
    border-radius: 50px;
    font-weight: 600;
    background-color: white;
    outline: none;
    border: 1px solid var(--dark);
    transition: background-color 0.3s ease;
}

.loading_btn:hover {
    background-color: var(--dark);
    color: white;
}

.case_studies_posts {
    margin-bottom: 40px;
}

.icon {
    padding-right: 6px;
    width: 30px;
}

.connect_icon {
    margin-right: 20px;
}

.use_case_info {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.use_case_info svg {
    margin-right: 10px;
}

.use_case_info .industry_icons {
    margin-top: 3px;
}

.use_case_info svg path {
    color: #039B81;
}

.hero_section_of_page {
    margin-bottom: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.hero_section_of_page .content {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.hero_section_of_page .content h2 {
    font-size: 44px;
    text-align: left;
    font-weight: 600;
}

.hero_section_of_page .data_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.hero_section_of_page .data_description {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.hero_section_of_page .services {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    align-items: center;
}

.hero_section_of_page .items_case {
    background-color: var(--gray);
    width: 100%;
    padding-top: 45px;
    padding-bottom: 30px;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60%;
    align-items: flex-start;
    row-gap: 15px;
}

.hero_section_of_page .items_case:hover {
    background-color: #df88f9;
}

.hero_section_of_page .items_case p {
    color: black;
    padding-left: 12px;
    margin-right: 12px;
}

.hero_section_of_page .case_detail {
    display: flex;
    flex-direction: row;
}

.hero_section_of_page .case_detail svg {
    color: white;
    width: 30px;
    height: 30px;
}

.hero_section_of_page .case_detail p {
    color: white;
}

.hero_section_of_page .items_list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    list-style: none;
    color: black;
    margin-bottom: 12px;
}

.hero_section_of_page .case_detail h4 {
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.hero_section_of_page .case_detail .icon {
    font-size: 26px;
    width: 30px;
    height: 30px;
    margin-right: 12px;
    color: white;
    position: relative;
}

.case_detail .connect_icon {
    font-size: 16px;
    margin-left: 22px;
    margin-top: 6px;
    position: relative;
}

.data_description img {
    width: 120px;
    margin-top: 30px;
}

.data_description h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
    margin-right: 40px;
    text-align: justify;
}

.challange_container {
    width: 100%;
    display: flex;
    align-items: center;
}

.challange_container .part1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 100px;
}

.part1 .experience {
    width: 90%;
    height: max-content;
    background-color: var(--gray);
}

.experience .parts {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 100px;
}

.parts span {
    font-size: 42px;
    font-weight: bold;
}

.project_d {
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

ul.bullet_list {
    margin-left: 40px;
}

.project_d h2 {
    font-weight: 500;
    line-height: 1.3;
    font-size: 32px;
}

.lists {
    padding-left: 12px;
}

.project_d p {
    text-align: left;
    line-height: 1.7;
    margin-bottom: 12px;
    margin-top: 20px;
}

.project_d .quotation {
    margin-left: 10px;
    padding: 32px;
    border-left: 4px solid #8a49a8;
}

.project_d .quotation h3 {
    font-weight: 300;
    text-align: left;
    line-height: 1.5;
    font-style: italic;
}

.project_d .quotation .name {
    text-align: left;
    margin-top: 18px;
    color: white;
    margin-bottom: 2px;
    font-weight: bold;
    letter-spacing: 1.2px;
}

.project_d h4 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.project_d ul li {
    list-style-type: square;
    text-align: left;
    line-height: 2;
}

.items_case {
    padding-top: 55px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

@media only screen and (max-width: 1100px) {
    .data_container {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .items_case {
        margin-bottom: 50px;
    }

    .services {
        width: 93%;
    }
}

.markdown_part2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 30px;
    background-color: #76D7CD;
    color: white;
}

.markdown_part2 p {
    margin-bottom: 10px;
}

@media only screen and (max-width: 970px) {

    .challange_container .part1 {
        flex-direction: column;
        width: 90%;
    }

    .experience .parts {
        justify-content: center;
        align-items: center;
    }

    .project_d h2 {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 900px) {

    .team {
        border: none;
    }

    .items {
        padding: 25px;
    }

    .project_d {
        padding-left: 0;
    }
}

@media only screen and (max-width: 700px) {

    .items_case {
        width: 100%;
    }

    .project_d {
        padding-left: 0;
    }

    .content h2 {
        font-size: 28px;
        text-align: left;
        line-height: 1.8;
        font-weight: 200;
    }

    .items_list {
        padding-left: 0;
    }

}

@media only screen and (max-width: 510px) {

    .details {
        flex-direction: column;
    }

    .data_description h2 {
        margin-right: 0;
    }

    .gallery .gallery1 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 20px;
    }

    .cases_container {
        width: 100%;
        margin-bottom: 50px;
    }

    .logo_name {
        font-size: 20px;
    }

    .data_description h2 {
        font-size: 18px;
    }
}