.case_studies .about_wrapper {
  display: flex;
  flex-direction: column;
}

.card_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  margin-top: 40px;
}

.case_study_card {
  position: relative;
  width: calc(45% - 20px);
  background: rgba(255, 255, 255, 0.9);
  color: var(--dark);
  text-decoration: none;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.case_study_card img {
  width: 100%;
  /* height: 450px; */
  object-fit: cover;
}

.case_study_card .case_study_badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  backdrop-filter: blur(5px);
}

.case_study_card .case_study_content {
  padding: 20px;
  text-align: left;
}

.case_study_card .company_name {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.case_study_card .title {
  font-size: 16px;
  margin: 10px 0;
}

.case_study_card .tags {
  color: rgb(223, 102, 122);
  text-decoration: underline;
  margin: 10px 0;
}

.case_studies .card_wrapper .case_study_card .description {
  font-size: 14px;
  color: #555;
  margin: 10px 30px 10px 0px;
  padding: 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
}

.case_study_card:hover {
  transform: scale(1.05);
}

.case_study_card:hover .read_more_circle {
  opacity: 1;
}

.read_more_circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: white;
  color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.image_container {
  position: relative;
  width: 100%;
}

.data_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.data_description {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.services {
  width: 40%;
  background-color: #76D7CD;
  padding: 60px 20px;
}

li.items_list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--dark);
  padding-bottom: 20px;
  padding-left: 30px;
  width: 100%;
}



span.case_detail {
  width: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .case_study_card {
    width: calc(100% - 20px);
  }
}

@media (max-width: 480px) {
  .card_wrapper {
    padding: 0;
    margin: 0;
  }

  .case_study_card {
    margin-bottom: 30px;
  }
}