.platforms_page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.platforms_container {
    width: 90%;
    text-align: left;
    overflow-x: hidden !important;
}

.platforms_container .header {
    text-align: left;
    margin-bottom: 3rem;
}

.platforms_container .page_title {
    margin-bottom: 5px;
}

.platforms_container .border {
    width: 60px;
    border: 3px solid transparent;
    border-color: black;
    margin-bottom: 20px;
}

.platforms_container .subtitle {
    font-size: 18px;
    color: var(--gray);
}

.platforms_container .technology {
    width: 100%;
    margin-bottom: 6rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.platforms_container .reverse {
    flex-direction: row-reverse;
}

.platforms_container .image_container {
    width: 40%;
    padding: 10px;
    padding-top: 20px;
}

.platforms_container .image_container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.platforms_container .technology_details {
    width: 40%;
    padding: 10px 10px 10px 4rem;
}

.platforms_container .reverse .technology_details {
    padding: 10px 4rem 10px 10px;
}

.platforms_container .technology_heading {
    font-size: 28px;
    margin-bottom: 5px;
}

.platforms_container .technology_subheading {
    font-size: 18px;
    color: var(--gray);
}

.platforms_container .tech_row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.tech_row .tech_col {
    width: 33.3333%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transition: all ease-in-out;
}

.tech_img_container:hover .tech_col {
    scale: 1.15;
}

.tech_col .tech_img_container {
    padding: 20px 20px 10px 20px;
    text-align: center;
}

.tech_col .tech_img_container img {
    width: 100%;
    height: 50px;
}

@media only screen and (max-width: 768px) {
    .platforms_container .header {
        margin-bottom: 2rem;
    }

    .platforms_container .technology {
        flex-direction: column;
        margin-bottom: 3rem;
    }

    .platforms_container .image_container {
        width: 100%;
    }

    .platforms_container .technology_details {
        width: 100%;
        padding: 10px;
    }

    .platforms_container .reverse .technology_details {
        padding: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .platforms_container .header {
        margin-bottom: 1rem;
    }

    .tech_row .tech_col {
        width: 50%;
    }
}